<template>
  <v-dialog v-model="visible" persistent max-width="800">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        Log Presensi
      </v-card-text>
      <v-divider class="mb-4" />
      <v-row>
        <v-col cols="3" class="py-1">
          <v-subheader class="caption headline-color font-weight-regular"
            >Nama/NIP</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-1">
          <v-text-field
            :value="`${form.nama} - ${form.NIP}`"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="py-1">
          <v-subheader class="caption headline-color font-weight-regular"
            >Tanggal</v-subheader
          >
        </v-col>
        <v-col cols="9" class="py-1">
          <v-menu
            ref="menu"
            v-model="menu"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                placeholder="Tanggal"
                append-icon="event"
                outlined
                dense
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
                disabled
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              locale="id"
              no-title
              scrollable
              disabled
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-3">
          <v-data-table
            id="table-custom-pegawai"
            class="mt-3"
            :headers="attendanceHeader"
            :items="historyAttendances"
            hide-default-footer
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      menu: false,
      form: {
        nama: null,
        NIP: null,
        date: null
      },
      historyAttendances: [],
      attendanceHeader: [
        {
          text: "Akses",
          value: "attend_for_name",
          sortable: false,
          align: "center"
        },
        {
          text: "Jam",
          value: "attend_time",
          sortable: false,
          align: "center"
        },
        {
          text: "Tipe",
          value: "attend_type_name",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form.nama = null;
        this.form.NIP = null;
        this.form.date = null;
      }
    },
    formProperty() {
      if (this.form.NIP && this.form.date) {
        this.getLogAbsensi();
      }
    }
  },
  computed: {
    formProperty() {
      return [this.form.NIP, this.form.date].join();
    }
  },
  methods: {
    show(item) {
      this.form.nama = item.nama;
      this.form.NIP = item.NIP;
      this.form.date = item.date;
      this.visible = true;
    },
    // Service
    async getLogAbsensi() {
      try {
        this.loading = true;
        await AttendanceService.logAbsensi({
          NIP: this.form.NIP,
          attend_date: this.$moment(this.form.date).format("YYYYMMDD")
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.historyAttendances = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
